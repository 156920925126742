import React from 'react'

export default function About() {
  return (
    <div className='container text-center mt-3'>
      <h1>About Page</h1>
     
    </div>
  )
}
